import { useParams } from "react-router-dom";

export const useGradeSubject = () => {
  const { grade, subject } = {
    grade: "3",
    subject: "math",
    ...useParams(),
  };

  return { grade, subject };
};

export const gradeToIndex = (grade) =>
  grade === "Alg-1" ? 9 : grade === "K" ? 0 : parseInt(grade, 10);

