import styled from "@emotion/styled";
import { useToggle } from "../../../../../../common/util/useToggle";
import React, { useCallback, useState } from "react";
import students from "../../../../data/students.json";
import {
  Cell,
  CellNoBorder,
  Header,
  HeaderLeftAlign,
  HeaderRow,
  HeaderRowNoBorder,
  LightRow,
  Row,
  YellowRow,
  Table,
} from "../../../../components/ReportTables";

import warning from "./warning.png";

import needHelp from "../../../../data/studentSummary.json";
import StudentActivityPopup from "../../Popup/StudentActivityPopup";
import {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../../../data/rollovertips";
import {
  SmallWhiteButton,
  BlueButton,
  SmallBlueButton,
} from "../../../../components/BlueButton";
import StudentPopup from "../../Popup/StudentPopup";
import Popup from "../../Popup/Popup";
import { LightColors } from "../../../../light-theme";
import MinusCircle from "./MinusCircle";
import PlusCircle from "./PlusCircle";
import Alert from "./Alert";
import { InfoIcon } from "../../FindContent2/by-strand/InfoIcon";
import { InfoTooltip } from "./InfoTooltip";

const ExpandButton = styled.div({
  display: "inline-block",
  position: "relative",
  top: 2,
  marginRight: 8,
  cursor: "pointer",
});

const WHATS_THIS_COLUMN_RELEASED = false;

const WhatsThisLink = styled.div({
  color: LightColors.orange,

  textDecoration: "none",
  position: "relative",
  img: {
    position: "relative",
    top: 3,
    marginRight: 4,
  },
  div: {
    //display: "none",
  },
});

const WhatThisPopup = styled.div({
  position: "absolute",
  backgroundColor: "#fff",
  padding: 15,
  left: -200,
  top: 35,
  zIndex: 10,
  color: "#000",
  fontSize: 14,
  fontWeight: "bolder",
  ".close": {
    position: "absolute",
    right: 15,
    top: 10,
  },
  ".triangle": {
    position: "absolute",
    right: 30,
    top: -15,
    width: 0,
    height: 0,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderBottom: "16px solid #fff",
  },
  p: {
    fontWeight: "normal",
  },
  width: 280,
  paddingBottom: 45,
  borderRadius: 5,
  boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
  button: {
    position: "absolute",
    right: 5,
  },
});

const LastWeek = styled.div({
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: LightColors.white,
  border: "1px solid #BFC0C0",
  padding: 20,
  fontSize: 16,
  marginTop: 15,
  marginBottom: 20,
});

const Link = styled.span({
  textDecoration: "none",
  color: LightColors.blue,
  fontWeight: "bold",
  cursor: "pointer",
  svg: {
    position: "relative",
    top: 4,
    marginRight: 4,
  },
});

const Circle = styled.div(({ color }) => ({
  display: "inline-block",
  width: 10,
  height: 10,
  borderRadius: 5,
  backgroundColor: color,
  marginRight: 3,
}));

const NotificationIcon = styled.div({
  label: "NotificationIcon",
  display: "inline-block",
  marginRight: 5,
  position: "relative",
  top: 5,
});

const maybeShadow = (index) => {
  if (index !== 0) return {};
  return {
    boxShadow: "inset 0 7px 9px -7px rgba(0,0,0,0.7)",
  };
};

// Vary input by +- 3 randomly
const wiggle = (index) => index + Math.floor(Math.random() * 7) - 3;

const PerformanceIcon = ({ performance }) => {
  const color =
    performance > 59
      ? LightColors.green
      : performance > 59
        ? LightColors.yellow
        : LightColors.red;
  return (
    <>
      {performance}% <Circle color={color} />
    </>
  );
};

const SummaryRow = ({
  id,
  student,
  currentBlock,
  learn,
  practice,
  link,
  index,
  time,
  lastLogin,
  grade,
  remediation,
  openStudentReport,
  blockProgress,
  onMoveStudent,
  performance,
  color,
}) => {
  const [expanded, toggleExpanded] = useToggle();

  return (
    <>
      <LightRow style={{ height: 80 }}>
        <CellNoBorder>
          <ExpandButton
            onMouseEnter={(ev) => {
              if (index != 0)
                rolloverTipBegin(
                  ev,
                  "Home_roster_viewblockdetails",
                  undefined,
                  150
                );
            }}
            onMouseLeave={rolloverTipEnd}
          >
            {expanded && <MinusCircle onClick={toggleExpanded} />}
            {expanded || <PlusCircle onClick={toggleExpanded} />}
          </ExpandButton>
          {/* 
          <Link
            onMouseEnter={(ev) => {
              if (index != 0)
                rolloverTipBegin(ev, "Home_roster_viewreportbutton");
            }}
            onMouseLeave={rolloverTipEnd}
            onClick={() => openStudentReport(index)}
          > */}
          {student}
          {/* </Link> */}
        </CellNoBorder>
        <CellNoBorder>{lastLogin}</CellNoBorder>
        <CellNoBorder>
          <b>{currentBlock}</b>
          <br />
          Grade {student.grade || grade}
        </CellNoBorder>
        <CellNoBorder>{learn}</CellNoBorder>
        <CellNoBorder>
          {performance === "--" && "--"}
          {performance > 0 && (
            <>
              <PerformanceIcon performance={performance} />
              {
                performance > 59 ? "Proficient" : "Not Proficient"
                // practice
              }
            </>
          )}
        </CellNoBorder>
        <CellNoBorder>
          {link && (
            <>
              <NotificationIcon>
                <Alert />
              </NotificationIcon>
              <Link onClick={onMoveStudent}>{link}</Link>
            </>
          )}
        </CellNoBorder>
      </LightRow>
      {expanded &&
        blockProgress &&
        blockProgress.map((block, index) => (
          <LightRow key={index} style={{ backgroundColor: "#f4f4f4" }}>
            <CellNoBorder></CellNoBorder>
            <CellNoBorder></CellNoBorder>
            <CellNoBorder>
              <b>{block.name}</b>
              <br />
              {block.type}
            </CellNoBorder>
            <CellNoBorder>{block.status}</CellNoBorder>
            <CellNoBorder colSpan={2}>{block.performance}</CellNoBorder>
            {/* <CellNoBorder colSpan={2}>{block.time}</CellNoBorder> */}
          </LightRow>
        ))}
    </>
  );
};

const ButtonBar = styled.div({
  display: "flex",
  gap: 10,
  span: {
    position: "relative",
    top: 8,
  },
  justifyContent: "flex-end",
});

const StudentAlertPopup = ({ student, onCancel, type }) => {
  const onMove = () => {
    console.info(student);
    // if (type === 'move') {
    //   student.learn = "Not Started";
    //   student.practice = "---";
    //   student.remediation = "";
    //   student.link = "";
    //   student.time = "0 min";
    //   student.currentBlock = student.nextBlock;
    // }
    onCancel();
  };

  const title = {
    heads: "Heads Up!",
    check: "Check In!",
    move: "Move to Next Assignment",
  }[type];

  const body = {
    check:
      "This student is rushing through this content block. They have not demonstrated proficiency in at least 2 consecutive lessons within a content block.",
    heads: (
      <div>
        <p>This student is taking an assignment again for more practice.</p>
        <Table>
          <thead>
            <HeaderRow>
              <Header style={{ padding: 12 }}>{student.currentBlock}</Header>
            </HeaderRow>
            <YellowRow>
              <td colSpan="6"></td>
            </YellowRow>
          </thead>
          <tbody>
            <LightRow>
              <Cell>{student.headsUp}</Cell>
            </LightRow>
          </tbody>
        </Table>
      </div>
    ),
    move: (
      <div>
        <p>
          This student seems to be struggling on the current assignment. Would
          you like to move them to the next assignment? They will see the
          assignment again at the end of the grade-level.
        </p>
        <Table>
          <tbody>
            <LightRow>
              <CellNoBorder>Current Assignment:</CellNoBorder>
              <CellNoBorder>
                <b>{student.currentBlock}</b>
              </CellNoBorder>
            </LightRow>

            <LightRow>
              <CellNoBorder>Next Assignment:</CellNoBorder>
              <CellNoBorder>
                <b>{student.nextBlock}</b>
              </CellNoBorder>
            </LightRow>
            <YellowRow>
              <td colSpan={2} />
            </YellowRow>
          </tbody>
        </Table>
      </div>
    ),
  }[type];

  return (
    <Popup onClose={onCancel} title={title} width={450}>
      {body}
      <br />
      <br />
      <ButtonBar>
        {type === "move" && (
          <SmallWhiteButton onClick={onCancel}>Cancel</SmallWhiteButton>
        )}
        {type === "move" && (
          <SmallBlueButton onClick={onMove}>
            Move to Next Assignment
          </SmallBlueButton>
        )}
        {type === "move" || (
          <SmallBlueButton onClick={onMove}>Okay</SmallBlueButton>
        )}
      </ButtonBar>
    </Popup>
  );
};

export const Summary = ({ grade, subject }) => {
  const [popup, _togglePopup, openPopup, closePopup] = useToggle();
  const [student, setStudent] = useState(null);
  //const [whatsThis, toggleWhatsThis] = useToggle();

  const [popupOpen, togglePopup] = useToggle();
  const [popupType, setPopupType] = useState(null);

  const onStudentLink = useCallback(
    (student) => () => {
      if (student.link) {
        // Grab first lower case first word of link for type.
        const type = student.link.split(" ")[0].toLowerCase();
        setPopupType(type);
      }
      togglePopup();
      setStudent(student);
    },
    [togglePopup]
  );

  const openStudentReport = useCallback(
    (studentIndex) => {
      if (studentIndex !== 0) return;
      const urlPath = `/reports-${subject}-${grade}-student_detail`;
      window.location.href = urlPath;
      // setStudent(students[studentIndex]);
      // openPopup();
    },
    [grade, subject]
  );

  const data = needHelp[subject][grade];

  const lastLogin = [
    "Yesterday",
    "2 days ago",
    "Yesterday",
    "Yesterday",
    "3 days ago",
    "1 month ago",
  ];

  return (
    <div>
      {/* <LastWeek style={{ color: "#434748", fontWeight: "bold" }}>
        <b style={{ color: "#292B2B" }}>Activity Last Week</b>
        <span>Class Average: 1h 12m</span>
        <span>Below Average Activity: 2</span>
        <span>Above Average Activity: 3</span>
        <span>Inactive: 3</span>
      </LastWeek> */}
      <Table style={{ fontWeight: "bold", color: "#232424" }}>
        <thead>
          <HeaderRowNoBorder>
            <HeaderLeftAlign width={200}>STUDENT</HeaderLeftAlign>
            <HeaderLeftAlign width={90}>LAST LOGIN</HeaderLeftAlign>
            <HeaderLeftAlign width={200}>CURRENT BLOCK</HeaderLeftAlign>
            <HeaderLeftAlign width={100}>STATUS</HeaderLeftAlign>
            <HeaderLeftAlign width={130}>
              PERFORMANCE
              <InfoTooltip tooltip="Performance scores are based on student work in Practice and Fluency assignments in the current block." />
            </HeaderLeftAlign>
            {/* <HeaderLeftAlign width={90}>TIME SPENT</HeaderLeftAlign> */}
            <HeaderLeftAlign width={185}>
              <WhatsThisLink>
                {" "}
                {/*onClick={toggleWhatsThis}> */}
                <NotificationIcon>
                  <Alert />
                </NotificationIcon>
                NOTIFICATIONS
                {/* {whatsThis && (
                  <WhatThisPopup>
                    <div className="triangle"></div>
                    <div className="close">⨉</div>
                    MOVE TO THE NEXT BLOCK
                    <p>
                      Student is struggling with the skills in this assignment
                      block. They will continue to work in this area unless
                      you move them to the next assignment block. Click{" "}
                      <b>Move to the Next Block</b> to move them to the next
                      block.
                    </p>
                    <SmallBlueButton>OK</SmallBlueButton>
                  </WhatThisPopup>
                )} */}
              </WhatsThisLink>
            </HeaderLeftAlign>
          </HeaderRowNoBorder>
        </thead>
        <tbody>
          <YellowRow>
            <td colSpan="6"></td>
          </YellowRow>
          {data.map((p, index) => (
            <SummaryRow
              onMoveStudent={onStudentLink(p)}
              key={index}
              index={index}
              grade={grade}
              openStudentReport={openStudentReport}
              lastLogin={lastLogin[index]}
              {...p}
            />
          ))}
        </tbody>
      </Table>
      {student && popup && (
        <StudentActivityPopup
          index={0}
          onClose={closePopup}
          title={`${student.lastName}, ${student.firstName}`}
          data={student}
          subject={subject}
          grade={grade}
        />
      )}
      {popupOpen && (
        <StudentAlertPopup
          onCancel={togglePopup}
          student={student}
          type={popupType}
        />
      )}
    </div>
  );
};
