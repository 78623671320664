import styled from "@emotion/styled";
import { gradeToIndex } from "./../../../../routing-util";
import React, { useCallback, useState } from "react";
import needHelp from "../../../../data/studentNeedsHelp.json";
import findcontent from "../../../../data/findcontent-with-blocks.json";
import {
  Cell,
  Header,
  HeaderNoBorderLeft,
  HeaderNoBorderRight,
  HeaderRow,
  YellowRow,
  Row,
  Table,
} from "../../../../components/ReportTables";
import { SmallBlueButton } from "../../../../components/BlueButton";
import { useHistory } from "react-router-dom";
import { LightColors } from "../../../../light-theme";

const mapNewStandard = (standard) => {
  return {
    subStandards: standard.subStandards.reduce((subAcc, subStandardName) => {
      subAcc[subStandardName] = [];
      return subAcc;
    }, {}),
  };
};

const SkillHeaderRow = styled(HeaderRow)({
  "> th": {
    padding: 15,
    fontSize: 20,
  },
});
const GroupRow = styled(Row)({
  "> td": {
    color: LightColors.blue,
    fontWeight: "bold",
    fontSize: 18,

  },
});

const SubSkillCell = styled(Cell)({ fontWeight: "bold", borderRight: "none" });
const StudentCell = styled(Cell)({
  color: LightColors.blue,
  fontWeight: "bold",
  borderRight: "none",
  borderLeft: "none",
});
const SkillRow = ({ skillName, subSkills, findContent }) => {
  const [open, setOpen] = useState(true);

  return (
    <React.Fragment>
      <GroupRow>
        <Cell colSpan={3} onClick={() => setOpen((o) => !o)}>
          {open ? "- " : "+ "}
          {skillName}
        </Cell>
      </GroupRow>
      {open &&
        Object.keys(subSkills).map((subSkillName) => (
          <Row>
            <SubSkillCell>{subSkillName}</SubSkillCell>
            <StudentCell style={{ width: 180 }}>
              {subSkills[subSkillName].join(", ")}
            </StudentCell>
            <Cell style={{ borderLeft: "none" }}>
              <SmallBlueButton onClick={findContent(subSkillName)}>
                Learn
              </SmallBlueButton>
            </Cell>
          </Row>
        ))}
    </React.Fragment>
  );
};

const findStrandId = (subject, grade, category) => {
  // const f = findcontent;
  // console.info(f);
  // debugger;
  const strand = findcontent[subject][gradeToIndex(grade)].strand.find(
    (s) => !!s.standard.find((st) => st.category == category)
  );
  if (strand) return strand.id;
  return null;
};

export const SkillGroups = ({ subject, grade }) => {
  const needHelpData = needHelp[subject][grade];
  const history = useHistory();

  const links = {};

  const findContent = (skillLink) => () => {
    console.info("skllLink", skillLink);
    const topLevelLink = (links[skillLink] || "").split(":")[0]
    const strandId = findStrandId(subject, grade, topLevelLink); //bdb6cbab-3c4e-43b2-a264-cc83ef5fc124
    if (topLevelLink && strandId) {
      history.push(
        `/findcontent/${subject}/${grade}/${grade}/strand/${strandId}/standards/${links[skillLink]}`
      );
    } else if (strandId) {
      history.push(
        `/findcontent/${subject}/${grade}/${grade}/strand/${strandId}/standards`
      );
    } else {
      history.push(`/findcontent/${subject}/${grade}/${grade}/strand`);
    }
  };

  const bySkill = needHelpData.reduce((acc, curr) => {
    curr.standards.forEach((standard) => {
      acc[standard.name] = acc[standard.name] || {};
      // For the learn button to link to, we need a mapping.
      if (standard.sublinks) {
        standard.sublinks.forEach((subLink, index) => {
          links[standard.substandards[index]] = subLink;
        });
      }
      standard.substandards.forEach((subStandardName, index) => {
        acc[standard.name][subStandardName] =
          acc[standard.name][subStandardName] || [];
        acc[standard.name][subStandardName].push(curr.student);
      });
    });

    return acc;
  }, {});

  return (
    <Table>
      <thead>
        <SkillHeaderRow>
          <HeaderNoBorderRight style={{ textAlign: "left", width: 700 }}>
            Skill Group
          </HeaderNoBorderRight>
          <HeaderNoBorderLeft colSpan={2} style={{ textAlign: "left" }}>
            Students
          </HeaderNoBorderLeft>
        </SkillHeaderRow>
        <YellowRow><td colSpan="3"></td></YellowRow>
      </thead>
      <tbody>
        {Object.keys(bySkill).map((skillName) => (
          <SkillRow
            skillName={skillName}
            subSkills={bySkill[skillName]}
            d={links[skillName]}
            findContent={findContent}
          />
        ))}
      </tbody>
    </Table>
  );
};
