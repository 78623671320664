import React, { Fragment, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Tab from "./Tab";
import Icon from "./Icon";
import Subject from "./Subject";
import Alerts from "../Contents/Message";
import { WaggleLogo } from "./WaggleLogo";
import PreviewIcon from "./PreviewIcon";
import RostersIcon from "./RostersIcon";
import SettingsIcon from "./SettingsIcon";
import HelpIcon from "./HelpIcon";

import { components } from "@indieasy.software/indieasy-components";

import rolloverTips, {
  rolloverTipBegin,
  rolloverTipEnd,
} from "../../data/rollovertips";
import { toolTips } from "@indieasy.software/indieasy-engine";

import unfilteredClasses from "../../data/classes";

import defaultUrl from "../../data/defaultUrl";
import { UserMenu } from "./UserMenu";
import { ClassPicker } from "./ClassPicker";
import { useGradeSubject } from "../../routing-util";
import { LightColors } from "../../light-theme";
import styled from "@emotion/styled";
import { useSubdomain } from "../../../../common/util/useSubdomain";

const { Markup } = components;

const TopNavContainer = styled.div({
  label: "TopNavContainer",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  height: 106,
  backgroundColor: LightColors.white,
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});

const SubHeader = styled.div({
  height: 42,
  marginLeft: 14,
  label: "SubHeader",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
});

const Spacer = styled.div({
  label: "Spacer",
  flexGrow: 2,
  flexShrink: 2,
});

const Divider = styled.div({
  label: "Divider",
  height: 52,
  borderLeft: `1px solid ${LightColors.grey1}`,
  flexGrow: 0,
  flexShrink: 0,
  marginLeft: 5,
  marginRight: 5,
});

const LogOutLink = styled.div({
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 16,
  marginLeft: 10,
  marginRight: 10,
});

const TopHeader = styled.div({
  label: "TopHeader",
  display: "flex",
  justifyContent: "space-around",
  flexDirection: "row",
  alignItems: "center",
  borderBottom: `1px solid ${LightColors.grey1}`,
  height: 57,
  width: "100%",
});

const WidthLimiter = styled.div({
  label: "WidthLimiter",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  // borderBottom: `1px solid ${LightColors.grey1}`,
  height: 52,
  width: 1120,
  position: "relative",
});

const TopNav = (props) => {
  const history = useHistory();
  const { calculatedSubDomain } = useSubdomain();
  const location = useLocation();
  const { isMath, isEla, defaultSubject } = useSubdomain();

  // Filter the classes based on the subdomain.
  const filterClass = (c) => {
    if (c.subject === "math" && isMath) {
      return true;
    }
    if (c.subject === "ela" && isEla) {
      return true;
    }
    return false;
  };
  const classes = {data:unfilteredClasses.data.filter(filterClass)}

  const [showAlert, setShowAlert] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  if (window.location.pathname === "/") {
    // When we load the page, if there is no class set, we want to make sure to go
    // to the right subject based on the hostname.
    history.push(`/home-${defaultSubject}-3`);
  }

  let [selectedId, pathSubject, pathGrade, data] = location.pathname
    .substring(1)
    .split(/[-/]/);

  if (selectedId === "reports") {
    selectedId = data === "national" ? "standards" : "track";
  }

  const subject = pathSubject || defaultSubject;
  const grade = pathGrade || "3";

  const findContentHandler = () => {
    history.push(`/findcontent/${subject}/${grade}`);
  };
  const selectHandler = (id) => {
    history.push("/" + id.toLowerCase() + "-" + subject + "-" + grade);
  };

  const trackAssignmentsHandler = () => {
    history.push("/reports-" + subject + "-" + grade + "-track");
  };

  const standardsHandler = () => {
    history.push("/reports-" + subject + "-" + grade + "-national");
  };

  const onClassPicked = (event) => {
    let id = selectedId;
    if (selectedId == "findcontentresults") id = "findcontent";
    const c = classes.data[event.target.selectedIndex];
    switch (selectedId) {
      case "findcontent":
        history.push(`/findcontent/${c.subject}/${c.grade}`);
        break;
      case "":
        history.push("/home-" + c.subject + "-" + c.grade);
        break;
      default:
        history.push("/" + id + "-" + c.subject + "-" + c.grade);
        break;
    }
  };

  const previewHandler = () => {
    
    window.open(
      `https://${calculatedSubDomain}.hmh-waggle-student.com/home-${subject}-${grade}`,
      "_blank"
    );
  };

  const contactsHandler = () => {
    window.open(
      `https://learning.hmhco.com/contact-waggle?utm_campaign=7015A000001qQYcQAM`,
      "_blank"
    );
  };
  const currentClass = classes.data.find(
    (c) => c.subject === subject && c.grade == grade
  );

  const homeSelected = selectedId == "home" || !selectedId;
  const findContentSelected =
    selectedId == "findcontent" || selectedId == "findcontentresults";
  const reportsSelected = !homeSelected && !findContentSelected;

  return (
    <TopNavContainer>
      <TopHeader>
        <WidthLimiter>
          <WaggleLogo />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Tab onSelect={selectHandler} id="home" selected={homeSelected}>
            Home
          </Tab>
          <Tab
            onSelect={findContentHandler}
            id="findcontent"
            selected={findContentSelected}
          >
            Find Content
          </Tab>
          {/* <Tab
            onSelect={trackAssignmentsHandler}
            id="reports"
            selected={selectedId == "track"}
          >
            Track Assignments
          </Tab> */}
          {/* <Tab
            onSelect={standardsHandler}
            id="reports"
            selected={selectedId == "standards"}
          >
            Standard Report
          </Tab> */}
          {/* <Tab id="reports" selected={false}>
            Resource Center
          </Tab> */}
          <Tab onSelect={selectHandler} id="reports" selected={reportsSelected}>
            Reports
          </Tab>
          <Spacer />
          <Divider />
          {currentClass?.hidePreview ? (
            <div style={{ width: 65 }} />
          ) : (
            <PreviewIcon onClick={previewHandler} />
          )}
          <RostersIcon title="Rosters" onClick={selectHandler} />
          <SettingsIcon
            onMouseEnter={(ev) => rolloverTipBegin(ev, "Home_alerts")}
            onMouseLeave={rolloverTipEnd}
            title="Settings"
          />
          <HelpIcon onClick={contactsHandler} />
          {/* <UserMenu /> */}
          <Divider />
          <LogOutLink>Log Out</LogOutLink>
        </WidthLimiter>
      </TopHeader>
      <SubHeader>
        <WidthLimiter>
          <ClassPicker onChange={onClassPicked} value={`${subject}-${grade}`} />
        </WidthLimiter>
      </SubHeader>
    </TopNavContainer>
  );
};

export default TopNav;
