import React, { useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { ContentByBlock } from "./ContentByBlock";
import { ContentByStrand } from "./by-strand/ContentByStrand";
import styled from "@emotion/styled";
import DropdownCss from "./Dropdown.module.css";
import DropDown from "../../../components/DropDown";
import rolloverTips from "../../../data/rollovertips";
import { useGradeSubject } from "../../../routing-util";
import { ContentByUnit } from "./by-unit/ContentByUnit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LightColors } from "../../../light-theme";

const Heading = styled.h1({
  //styleName: UI Text/Big 2 - Open Sans Bold 22pt;
  fontFamily: "Open Sans",
  fontSize: 22,
  fontWeight: 700,
  lineHeight: "30px",
  letterSpacing: "0em",
  textAlign: "left",
  marginLeft: 20,
  marginBottom: 20,
});

const Options = styled.div({
  display: "flex",
  gap: 10,
  paddingLeft: 8,
  marginLeft: 20,
  marginRight: 20,
  backgroundColor: LightColors.white,
  height: 50,
  border: `1px solid ${LightColors.grey1}`,
  "& label": {
    fontWeight: 600,
    padding: 12,
  },
});

const grades = [
  { text: "K" },
  { text: "1" },
  { text: "2" },
  { text: "3" },
  { text: "4" },
  { text: "5" },
  { text: "6" },
  { text: "7" },
  { text: "8" },
  { text: "Alg-1" },
];



const allGrades = grades.map((g) => g.text);

const allReportTypes = {
  ela: [
    { text: "All Resources", code: "block", grades: allGrades },
    { text: "By Strand", code: "strand", grades: allGrades },

    {
      text: "Into Lit Passage",
      code: "into_lit_passage",
      grades: ["6", "7", "8"],
    },
    {
      text: "Into Reading Week ",
      code: "into_reading_week",
      grades: ["2", "3", "4", "5", "6"],
    },
    { text: "Wonders®️", code: null, grades: allGrades },
    { text: "StudySync®️", code: null, grades: allGrades },
    { text: "MyPerspectives®️", code: null, grades: allGrades },
    { text: "MyView Literacy®️", code: null, grades: allGrades },
  ],
  math: [
    { text: "All Resources", code: "block", grades: allGrades },
    { text: "By Domain ", code: "strand", grades: allGrades },

    { text: "Reveal®️", code: null, grades: allGrades },
    { text: "Envision Math®️", code: null, grades: allGrades },
    { text: "Illustrative Math®️", code: null, grades: allGrades },

    {
      text: "Into Math®️",
      code: "into_math_lesson",
      grades: ["2", "3", "4", "5", "6", "7", "8", "Alg-1"],
    },
    {
      text: "Go Math!®️",
      code: "go_math_lesson",
      grades: ["2", "3", "4", "5", "6", "7", "8", "Alg-1"],
    },
    {
      text: "Math in Focus®️",
      code: "math_focus_lesson",
      grades: ["2", "3", "4", "5", "6", "7", "8", "Alg-1"],
    },
    {
      text: "Math Expressions®️",
      code: "math_expressions_lesson",
      grades: ["2", "3", "4", "5", "6"],
    },
    //{ text: "Math Expressions Vol. 2", code: "math_expressions_lesson" },
  ],
};

export const FindContent2 = ({ groups }) => {
  const match = useRouteMatch();
  const { grade: classGrade, subject } = useGradeSubject();
  
  const dropdownGrades = grades.filter((g) => subject === "math" || g.text !== "Alg-1" );

  const { report, effectiveGrade: grade } = {
    report: "block",
    effectiveGrade: classGrade,
    ...useParams(),
  };
  console.info("Find content ", { classGrade, grade });
  const reportTypes = allReportTypes[subject];
  const activeReports = reportTypes.filter((r) => r.grades.indexOf(grade) > -1);
  const [languageIndex, setLanguageIndex] = useState(0);
  
  const gradeIndex = grades.findIndex((g) => g?.text === grade);
  const history = useHistory();

  const setGradeIndex = (gradeIndex) => {
    const targetGrade = grades[gradeIndex].text;
    history.push(
      `/findcontent/${subject}/${classGrade}/${targetGrade}/${report}`
    );
  };

  const reportTypeIndex = activeReports.findIndex((r) => r.code === report);

  if (reportTypeIndex === -1) {
    return (
      <Redirect to={`/findcontent/${subject}/${classGrade}/${grade}/block`} />
    );
  }

  const setReportTypeIndex = (index) => {
    const targetReport = activeReports[index];
    const { code } = targetReport;
    if (code == null) {
      console.info(
        `${targetReport.text} is not available in this demo, please choose another option.`
      );
      toast(
        `${targetReport.text} is not available in this demo, please choose another option.`
      );
      return;
    }
    history.push(`/findcontent/${subject}/${classGrade}/${grade}/${code}`);
  };

  return (
    <div>
      <Heading>Find Content</Heading>
      <Options>
        <label>Grade:</label>
        <DropDown
          css={DropdownCss}
          selectedIndex={gradeIndex}
          selectIndex={setGradeIndex}
          data={dropdownGrades}
        />
        <label>View By:</label>
        <DropDown
          css={DropdownCss}
          selectedIndex={reportTypeIndex}
          selectIndex={setReportTypeIndex}
          data={activeReports}
        />
        <label>Language:</label>
        <DropDown
          css={DropdownCss}
          selectedIndex={languageIndex}
          selectIndex={setLanguageIndex}
          data={[{ text: "English" }, { text: "Spanish" }]}
          //rolloverTipTitle={rolloverTips["TA_studentsdropdown"].title}
          //rolloverTip={rolloverTips["TA_studentsdropdown"].text}
        />
      </Options>

      <Switch>
        <Route path={`/findcontent/:subject/:grade/:effectiveGrade/strand`}>
          <ContentByStrand
            groups={groups}
            classGrade={classGrade}
            grade={grade}
            label={reportTypes[reportTypeIndex].text}
            languageIndex={languageIndex}
          />
        </Route>

        <Route path={`/findcontent/:subject/:grade/:effectiveGrade/block`}>
          <ContentByBlock
            classGrade={classGrade}
            grade={grade}
            groups={groups}
            languageIndex={languageIndex}
          />
        </Route>

        <Route path={`/findcontent/:subject/:grade/:effectiveGrade/:unitType`}>
          <ContentByUnit
            classGrade={classGrade}
            grade={grade}
            groups={groups}
            label={reportTypes[reportTypeIndex].text}
            languageIndex={languageIndex}
          />
        </Route>

        <Route>
          <ContentByBlock
            classGrade={classGrade}
            grade={grade}
            groups={groups}
            languageIndex={languageIndex}
          />
        </Route>
      </Switch>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};
